<template>
	<ion-page>
		<ion-header :translucent="true">
			<ion-toolbar>
				<ion-back-button
					color="medium"
					default-href="/conversation"
					text=""
					slot="start"
				>
				</ion-back-button>

				<ion-list lines="none">
					<ion-item>
						<!-- <ion-avatar>
							<img src="../../assets/images/avatar.svg" href="#" />
						</ion-avatar> -->
						<ion-label>
							<h2>
								{{ patientName != null ? patientName : "Pasien" }}
							</h2>
							<p>({{ ticketID }} - riwayat percakapan)</p>
						</ion-label>
					</ion-item>
				</ion-list>
				<ion-progress-bar
					v-if="store.state.isLoading"
					type="indeterminate"
				></ion-progress-bar>
			</ion-toolbar>
		</ion-header>

		<ion-content scroll-y="false">
			<div
				class="bgChat"
				ref="scroll"
				v-if="jsonMessage != null"
				:style="chatBg"
			>
				<!-- <pre>{{ jsonMessage }}</pre> -->
				<div v-for="(chat, index) in jsonMessage" :key="index">
					<div class="bubbleWrapper">
						<div
							:class="[
								userOwn == chat.userId
									? 'inlineContainer own'
									: 'inlineContainer',
							]"
						>
							<div
								:class="[
									userOwn == chat.userId
										? 'ownBubble own'
										: 'otherBubble other',
								]"
							>
								{{ chat.message }}
								<div v-if="chat.uploadPhotoUrl != ''">
									<img
										class="image"
										:src="[chat.uploadPhotoUrl]"
										@click="viewImage(chat.uploadPhotoUrl)"
									/>
									<!-- last ion modal -->
								</div>
								<br />
							</div>
						</div>
						<span :class="[userOwn == chat.userId ? 'own' : 'other']">{{
							chat.time
						}}</span>
					</div>
				</div>
				<!-- modal popup image -->
				<ion-modal
					backdrop-dismiss="false"
					:is-open="isOpenRef"
					css-class="my-custom-class"
				>
					<div>
						<ion-header>
							<ion-toolbar>
								<ion-buttons slot="end">
									<ion-chip color="ari" @click="isOpenRef = !isOpenRef"
										>Close</ion-chip
									>
								</ion-buttons>
							</ion-toolbar>
						</ion-header>
						<img class="imgPopup" :src="[imgPopUp]" />
					</div>
				</ion-modal>
				<!-- END modal popup image -->
			</div>
		</ion-content>
	</ion-page>
</template>

<script>
import { useRoute, onBeforeRouteLeave, useRouter } from "vue-router";
import { inject, ref, computed } from "vue";
import {
	IonPage,
	IonHeader,
	IonToolbar,
	IonBackButton,
	IonButtons,
	// IonButton,
	IonList,
	IonItem,
	// IonAvatar,
	IonLabel,
	IonContent,
	IonModal,
	IonProgressBar,
	IonChip,
	onIonViewWillEnter,
} from "@ionic/vue";
export default {
	components: {
		IonPage,
		IonHeader,
		IonToolbar,
		IonBackButton,
		IonButtons,
		// IonButton,
		IonList,
		IonItem,
		// IonAvatar,
		IonLabel,
		IonContent,
		IonModal,
		IonProgressBar,
		IonChip,
	},
	state: {
		availability: "tersedia",
	},
	setup() {
		const store = inject("store");
		const route = useRoute();
		const router = useRouter();
		//get ticket ID from params
		const ticketID = computed(() => route.params.id);
		const jsonMessage = ref(null);
		const patientName = ref(null);
		//set variable modal
		const isOpenRef = ref(false);
		const imgPopUp = ref(null);
		const setOpen = (state, url) => {
			isOpenRef.value = state;
			imgPopUp.value = url;
		};
		const chatBg = ref({
			backgroundImage: `url(${require("@/assets/relidoc/chat-bg.jpg")})`,
		});
		//get information of ticket
		async function getTicketInformation() {
			//parsing data
			//console.log(localStorage.getItem("doctorChatHistory"));
			//const messageString = r.data.TICKET_CHAT_HISTORY;
			const json =
				JSON.parse(localStorage.getItem("doctorChatHistory")) != null
					? JSON.parse(localStorage.getItem("doctorChatHistory"))
					: null;
			if (json != null && json.conversation != null) {
				const ordered = Object.keys(json.conversation)
					.sort()
					.reduce((obj, key) => {
						obj[key] = json.conversation[key];
						return obj;
					}, {});
				jsonMessage.value = ordered;
			}
			patientName.value = localStorage.getItem("doctorChatHistoryPatientName");
			// try {
			// 	//store.state.isLoading = true;
			// 	//const r = await store.ticket.ticketInformation(ticketID.value);

			// 	if (r.status === 200 && r.data.ResponseCode == "000") {

			// 		//store.state.ticket.ticketInfo = jsonMessage.value;
			// 	}
			// } catch (err) {
			// 	console.log(err);
			// }
			//store.state.isLoading = false;
		}
		function viewImage(urlImage) {
			router.push(`/view-image?url=${urlImage}&parentRoute=TicketComplete`);
		}
		onIonViewWillEnter(() => {
			getTicketInformation();
		});
		onBeforeRouteLeave(() => {
			localStorage.removeItem("doctorChatHistoryPatientName");
			localStorage.removeItem("doctorChatHistory");
		});
		//set userOwn for css chat
		const userOwn = computed(function() {
			return localStorage.getItem("DoctorId");
		});

		return {
			store,
			ticketID,
			isOpenRef,
			imgPopUp,
			setOpen,
			userOwn,
			jsonMessage,
			patientName,
			chatBg,
			viewImage,
		};
	},
	methods: {},
};
</script>
<style scoped>
.bgChat {
	height: calc(100% - 12px);
	min-height: 700px;
	position: relative;
	background: #e8e8e8;
	z-index: 0;
	overflow: scroll;
	padding-bottom: 300px;
	/* margin-bottom: 50px; */
	background: center;
	background-size: cover;
}
.bubbleWrapper {
	padding: 4px 10px;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	align-self: flex-end;
	color: #fff;
}
.inlineContainer {
	display: inline-flex;
}
.inlineContainer.own {
	flex-direction: row-reverse;
}
.inlineIcon {
	width: 20px;
	object-fit: contain;
}
.ownBubble {
	min-width: 60px;
	max-width: 330px;
	padding: 14px 18px;
	margin: 18px 8px;
	background-color: #d8ffc6;
	border-radius: 10px 10px 0 10px;
	border: 1px solid #d8ffc6;
	color: #000;
	-webkit-box-shadow: 0px 10px 3px -11px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 10px 3px -11px rgba(0, 0, 0, 0.75);
	box-shadow: 0px 10px 3px -11px rgba(0, 0, 0, 0.75);
	font-size: 14px;
	padding-bottom: 20px;
	margin-bottom: 6px;
}
.otherBubble {
	min-width: 60px;
	max-width: 330px;
	padding: 14px 18px;
	margin: 6px 8px;
	background-color: #ffffff;
	border-radius: 10px 10px 10px 0;
	border: 1px solid #ffffff;
	color: #000;
	-webkit-box-shadow: 0px 10px 2px -11px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 10px 2px -11px rgba(0, 0, 0, 0.75);
	box-shadow: 0px 10px 2px -11px rgba(0, 0, 0, 0.75);
	font-size: 14px;
	padding-bottom: 20px;
}
.own {
	align-self: flex-end;
}
.other {
	align-self: flex-start;
}
span.own,
span.other {
	font-size: 11px;
	color: grey;
	margin-top: -24px;
}
span.own {
	margin-right: 24px !important;
}
span.other {
	margin-left: 24px !important;
}
.image {
	padding-top: 5px;
	margin-bottom: -15px;
	cursor: pointer;
}
.imgPopup {
	margin: 0 auto;
	width: 100%;
	height: auto;
}
/* .time {
	font-size: 12px;
	color: grey;
} */
ion-avatar {
	margin-right: 10px;
}
a {
	outline: none;
}
.chevronBackOutline {
	color: gray;
	font-size: 28px;
	margin-right: 15px;
}
</style>
